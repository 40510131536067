// Bootstrap library.
@import 'bootstrap';

// Base-theme overrides.
@import 'overrides';

@import 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,600,900';

.cover () {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

html {
  position: relative;
  min-height: 100%;
}
body {
  &.admin-menu {
    .navbar {
      &.container-fluid {
        @media (max-width: @screen-md-max){
          //margin-top: 20px;
        }
      }
    }
  }
  /* Margin bottom by footer height */
  margin-bottom: 60px;
}

h1, .h1,
h2, .h2,
h3, .h3 {
 margin-top: (@line-height-computed / 2);
 margin-bottom: (@line-height-computed / 2);
}
h1, .h1,
h2, .h2, {
  margin-top: @line-height-computed;
  margin-bottom: @line-height-computed;
}

h1, .h1, h2, .h2, h3, .h3,
h4, .h4, h5, .h5, h6, .h6 {

}

h4 {
  font-weight:600;
}

table {
  .contextual-links-wrapper {
    position:relative;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  background-color: #f7f7f7;
  .inner {
    margin-top: 60px;
    margin-bottom: 20px;
  }
  .block {
    margin-bottom: 30px;
  }
  .block-title {
    font-size: 16px;
    margin-top: 0;
    font-weight: 600;
  }
  a {
    color: @text-color;
    &:hover {
      color: @brand-danger;
    }
  }
  .nav {
    > li {
      &.first {
        a {padding-top: 0;}
      }
      > a {
        padding: 10px 0px;
        color: @text-color;
        &:hover, &:focus, &.active {
          background-color: transparent;
          color: @brand-danger;
        }
      }
    }
  }
  #block-bean-footer-logo {
   img {
     max-width: 160px;
   }
  }
  .field-name-field-images {
    > .field-items {
      > .field-item {
        margin-bottom: 25px;
        img {
          max-width: 90px;

        }
      }
    }
  }
}

.lg-nopadding {
 padding: 0;
}

.header-bg {
  min-height:86px;
  height: 200px; //30vh;
  .cover();
  position:relative;

  &:before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin: 0 auto;
    //background-color: rgba(7,12,25,.05);
    background: url('../images/fade.png') no-repeat center;
    .cover();
    z-index:1;
  }
}

.node-type-site-contact-form .header-bg {
  margin-bottom: 0px;
}
.front .header-bg {
  height: 100vh;
}
.front.logged-in .header-bg {
  height: calc(~"100vh - 29px");
}

video.fullscreen-bg__video {
  position: absolute;
     top: 50%;
     left: 50%;
     width: auto;
     height: auto;
     min-width: 100%;
     min-height: 100%;
     -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
         -ms-transform: translate(-50%, -50%);
 	    transform: translate(-50%, -50%);
}
@media (max-width: @grid-float-breakpoint) {
    video {
        display:none;
    }
}


.navbar {
  border-color: transparent;
  .logo {
    margin-top: 15px;
    img {
      -webkit-transition: width .2s;
      -moz-transition: width .2s;
      transition: width .2s;
      width: 207px;
    }
  }
  &.container-fluid {
    margin-top: 0;
  }
  &.navbar-fixed-top {
    &.affix, &.expanded {
      background-color: rgba(25,25,25,.95);
      border-color: rgba(25,25,25,.95);
    }
    &.affix {
      .logo img {
        width: 80px;
      }
    }
  }
  .navbar-nav {
    @media (min-width:@grid-float-breakpoint){
      float:right;
      > li > a {
        padding: 20px;
      }
      /*> li.last > a {
        background-color: @brand-primary;
        .btn;
        .btn-primary;
        margin-top: 24px;
        border-radius: 5px;
      }*/
    }
  }
  .navbar-toggle {
    margin-top: 15px;
  }
}

.page-header {
  border-bottom: 0;
  margin-bottom:0;
}
.region.region-navigation {
  text-align: right;
}
.region.region-content {
  padding-bottom:100px;
  padding-top:0px;
}
.node-type-site-contact-form {
  .region.region-content {
    padding-bottom:0px;
    padding-top:0px;
  }
}

/* Language block */
#block-locale-language {
  position:relative;
  top: 10px;
  @media (min-width:@grid-float-breakpoint){
    top: 20px;
    .pull-right;
  }
  @media (min-width:1400px){
    position: absolute;
    right: 15px;
    top: 20px;
  }
  .language-switcher-locale-url {
    list-style:none;
    padding:0;
    > li {
      display: inline;
      border-right: 1px solid #fff;
      &:last-child {
        border: none;
      }
        > a {
        color: @navbar-default-link-color;
        padding: 0 15px;
        text-decoration:none;
        &.active, &:hover {
          color: @navbar-default-link-active-color;
        }
      }
    }
  }
}


.highlighted {
  position:relative;
}
#block-bean-intro {
  margin-top:30px;
  margin-bottom: 30px;
  @media (max-width: @screen-xs-max){
    padding-right:30px;
  }
  @media (max-width: @screen-md-max){
    padding-left:30px;
  }
  h3 {
    font-size: 24px;
    font-weight: 600;
    text-transform:uppercase;
    color: #0979c4;
  }
  p {
    font-size: 17px;
  }
}
.region.region-highlighted {
  //.container();
  @media (min-width: @screen-sm-min) {
    display: flex;
    align-items: center;
  }
}


/* Services */
#block-bean-services-image {
  padding-right: 0;
  padding-left: 0;
  @media (min-width:@screen-sm-min){
    padding-right: 0;
    padding-left: 15px;
    //position: absolute;
    //right: 0;
    .field-name-field-images {
      .img-responsive {
        max-height: 500px;
        width: auto;
        float: right;
      }
    }
  }
  .bg-image {
    width: 100%;
    height: 500px;
    background-position: center center;
    .cover();
  }
}



/*Frontpage slide */
.carousel-caption {
  text-align: left;
  top: 60%;
  left: 0;
  right: 0;
  @media (max-width: @screen-xs-max){
    padding-right:30px;
  }
  @media (max-width: @screen-md-max){
    padding-left:30px;
  }
  -webkit-transform: translateY(-60%);
  -moz-transform: translateY(-60%);
  transform: translateY(-60%);
  .caption-title {
    padding-left:0;
    h1, h2 {
      line-height: 1.3;
      text-transform: uppercase;
      font-size: 7vw;
      @media (min-width: @screen-sm-min){
        font-size: 4vw;
      }
      @media (min-width: @screen-lg-min){
        font-size: 3vw;
      }
      font-weight: 900;
      margin-top: 0;
    }
  }
  .caption-body {
    padding-left:0;
    padding-right:0;
    p {
      font-size: 18px;
       margin-bottom: 30px;
    }
    @media (min-width: @screen-sm-min){
      border-left: 1px solid #fff;
      padding-left: 60px;
      padding-right: 60px;
      p {
        font-size: 25px;
      }
    }
  }
  .btn.btn-default {
    background-color: transparent;
    color: #fff;
    padding: 15px 40px;
    border-radius: 5px;
  }
}
.not-front #block-views-frontpage-slide-block-1 {

}

#page-header {
  position:relative;	
}
.page-title {
  position:absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #fff;
}

#block-bean-contact-link {
  background-color:@brand-primary;
  .content {
    padding-top: 50px;
    padding-bottom: 50px;
    color: #fff;
    .container();
    .field-name-title-field {
      font-weight: 300;
      font-size: 29px;
      .make-sm-column(8);
    }
    .field-name-field-link {
      .make-sm-column(4);
      position:relative;
      z-index:5;
      .btn.btn-default {
        background-color: transparent;
        color: #fff;
        padding: 15px 40px;
        border-radius: 5px;
      }
    }
  }
}


/* References */
.pav-international,
.page-references .view-references {
  margin-top: 80px;
}
.view-references, .pav-international {
 table, thead, tbody, tr {display: block; width: 100%; white-space: nowrap;}
  tr {
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    @media (min-width: @grid-float-breakpoint) {
      flex-direction: row;
    }
  }
  th, td {
    display: block;
    white-space: normal;
    width: 20%;
    &.views-field-title-field {
      width: 30%;
    }
    &.views-field-field-year {
      width: 10%;
    }
    border: 0 !important;
    padding-top: 10px;
  }

  .view-content {
    margin-bottom: 30px;
  }
  .node-reference {
    margin-bottom: 30px;
    .group-image.field-group-div {
      position:relative;
    }
    .field-name-field-category {
      display: inline-block;
      position: relative;
      background-color:@brand-danger;
      color: #fff;
      left:0;
      padding: 10px 20px;
      text-transform: uppercase;
      top: -80px;
      @media (min-width: @screen-lg-min){
        white-space: nowrap;
      }
    }
    .field-name-title-field {
      display: inline-block;
      margin-right: 10px;
      position:relative;
      &:after {
        content: ", ";
        position:absolute;
        top: 0;
        right: -5px;
      }
    }
    .field-name-field-year {
      display: inline-block;
    }
    .field-name-field-customer {
      margin-top: -30px;
      h4 {
        font-weight: 600;
        margin-top: 0px;
        margin-bottom: 5px;
        a {
          text-decoration: none;
          color: @text-color;
        }
      }
    }
  }
  .more-link {
    margin-top: 50px;
    text-align: center;
    > a {
      .btn;
      .btn-default;
      background-color: transparent;
      padding: 15px 60px;
      color: @text-color;
      border-radius: 5px;
    }
  }
  .isotope-filters {
    list-style:none;
    text-align: center;
    padding: 0;
    margin-bottom: 60px;
    .isotope-filter-item {
      display: inline-block;
      a {
        .btn;
        .btn-default;
        text-decoration:none;
        color:@brand-danger;
        border-color: @brand-danger;
        padding: 8px 12px;
        &:hover,
        &.active {
          background-color: @brand-danger;
          border-color: @brand-danger;
          color: #fff;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }
    }
  }
}

#block-views-references-block {
  margin-top: 80px;
  margin-bottom: 80px;
  //.container();
}

.page-pav-sweden-ab,
.page-pav-norway-as,
.page-references {
  .pav-international,
  .view-references {
    .container();
    .view-content {
      .row();
    }
    .view-footer {

    }
  }
}

#block-bean-pav-norway-as,
#block-bean-pav-sweden-ab {
  margin-bottom: 50px;
}

.field-name-field-map {
  .row();
}

#webform-client-form-7 {
  .control-label {
    a {
      text-decoration: underline;
    }
  }
  .grippie {
    display:none;
  }
  .form-control {
    border-color: #fff;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .form-actions {
    text-align:right;
  }
  .webform-component-markup {
    font-size: 22px;
    font-style:italic;
  }
}

#block-views-employees-block {
  .container();
  margin-top: 70px;
  margin-bottom: 20px;
  .block-title {
    margin-bottom: 90px;
    font-style: italic;
  }
}
.node-employee {
  .field-name-field-image img {
    border-radius: 50% 50% 50% 0%;
    max-width: 270px;
  }
  .field-name-title-field {
    h3 {
      margin-bottom: 10px;
      font-weight: 300;
    }
  }
  .field-name-field-work-title {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .field-label {
    font-weight: normal;
  }
}
.view-employees {
  .node-employee {
    margin-bottom: 70px;
  }
}

.node-site-contact-form {
  .field-name-body,
  .webform-client-form {
    .make-sm-column(6);
    padding-left: 0;
    padding-right: 0;
    h1,h2,h3 {
      margin-top: 0;
    }
  }
  > .row {
    &:first-child {
      padding-top: 100px;
      padding-bottom: 70px;
      background-color: #f6f6f6;
    }
  }
}
.page-services {
  .view-services {
    margin-top: 80px;
    > .view-content > .row {
      > .node {
        .container();
        .field-name-title-field {
          h3 {
            text-transform: uppercase;
            font-size: 24px;
            font-weight: 600;
          }
        }
        .field-name-body {
          h1 {

          }
          p {
            font-size: 17px;
          }
        }
      }
      &:nth-child(1){
        padding-bottom: 120px;
      }
      &:nth-child(2){
        background: url('../images/services-bg.jpg') no-repeat;
        .cover();
        color: #fff;
        padding-top: 160px;
        padding-bottom: 160px;
      }
      &:nth-child(3){
        padding-top: 120px;
      }
    }
  }
}

.node-type-reference {
  .field-name-field-images {
    margin-bottom: 60px;
  }
  .group-sidebar {
    border-right: 1px solid #e9e9e9;
    .field-label {
      font-weight: 600;
      text-transform: uppercase;
    }
    .field-items {
      font-style: italic;
    }
  }
}


.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  text-align: center;

  li {
    display: inline-block;
    width:  15px;
    height: 15px;
    margin: 0 10px;
    text-indent: -999px;
    border: 1px solid @carousel-indicator-border-color;
    border-radius: 10px;
    cursor: pointer;
    vertical-align: middle;
    background-color: #f8f8f8;
    background-color: rgba(248,248,248,1);
  }
  .active {
    width:  15px;
    height: 15px;
    background-color: @brand-danger;
    border: 1px solid @brand-danger;
  }
}

#sliding-popup .agree-button.eu-cookie-compliance-default-button {
    .btn;
    .btn-default;
    background-color: transparent;
    color: #fff;
    text-shadow: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 15px;
    &:hover {
      background-color: transparent;
      color: #fff;
      text-shadow: none;
      border-color: darken(#ccc, 5%);
    }
}
